import React from 'react';

import {
  Row,
  Col,
  CardDeck,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from 'reactstrap';
import classnames from 'classnames';

import styles from './skeleton.module.scss';
<div className={styles['body-comp-logo']}></div>;

const SkeletonChannelsSearchGridItems = () => {
  const counter = 10;
  return (
    <>
      {(() => {
        const SkeletonCard = [];
        for (let i = 1; i <= counter; i++) {
          SkeletonCard.push(
            <Row key={`SkeletonItem-channel-row-` + i} className={classnames(styles['vertical-list'], 'g-4')}>
              <Col sm='12'>
                <CardDeck className={classnames('h-100')}>
                  <Card className={classnames(styles['horizontal-card'], styles['ChannelsCard'], styles['recorded'], styles['no-img-hover'], 'h100')}>
                    <Row xs='1' sm='2' className={classnames(styles['search-channels-item'], 'h-100')}>
                      <Col className={styles['col-1-item-body']}>
                        <CardBody className={styles['cardbody']}>
                          <div className={styles['main-body']}>
                            <CardTitle key={`SkeletonItem-channel-title-` + i} className={styles['item-title']}></CardTitle>
                            <CardText key={`SkeletonItem-channel-meta-` + i} className={classnames(styles['item-meta'])}></CardText>
                            <CardText key={`SkeletonCard-channel-description-` + i} className={classnames(styles['card-description'])}></CardText>
                          </div>
                        </CardBody>
                      </Col>
                      <Col className={styles['col-2-img']}>
                        <div key={`SkeletonImg-channel-wrap-` + i} className={classnames(styles['img-wrap'], styles['search-img-wrap'])}></div>
                      </Col>
                    </Row>
                  </Card>
                </CardDeck>
              </Col>
            </Row>
          );
        }
        return SkeletonCard;
      })()}
    </>
  );
};

export default SkeletonChannelsSearchGridItems;
