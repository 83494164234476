export interface Statistics {
  upcomingCommunications: number;
  liveCommunications: number;
  recordedCommunications: number;
}

/**
 *
 * Takes in the Statistics array and counts each Statistics type to work out the total session.
 * @param {*} Statistics : Statistics array.
 * @returns number
 */

function talksCountByChannelId(Statistics: Statistics) {
  let countCommunications = 0;
  if (Statistics) {
    countCommunications =
      Number(Statistics['upcomingCommunications']) +
      Number(Statistics['liveCommunications']) +
      Number(Statistics['recordedCommunications']);
  }
  return countCommunications;
}

export default talksCountByChannelId;
