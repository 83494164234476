import React, { FC } from 'react';
import styles from './index.module.scss';
import SectionHeadings from '../SectionHeadings/SectionHeadings';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight }  from '@fortawesome/pro-light-svg-icons';
import { faCircleInfo }    from '@fortawesome/pro-solid-svg-icons';

interface headingProps {
 messageTitle?: string;
 message: string;
 title: string;
 headingBlockName: string;
 dataBddTitle: string;
 classes?: string;
 messageLinkText: string;
 messageLinkUrl: string;
}

const MyBrighttalkMessageHeadings: FC<headingProps> = ({
 messageTitle,
 message,
 title,
 headingBlockName,
 dataBddTitle,
 classes,
 messageLinkText,
 messageLinkUrl
}) => {

  return (
   <>
    <SectionHeadings
      title={title}
      headingBlockName={headingBlockName}
      dataBddTitle={dataBddTitle}
      classes={classes}
    />

    {messageTitle && (

     <p className={styles['message-title']}>{messageTitle}</p>

    )}

    <p className={styles['mybrighttalk-message-link']}>
      <FontAwesomeIcon icon={faCircleInfo} />
      { message }
        <a href={messageLinkUrl}>
         {messageLinkText}
         <FontAwesomeIcon icon={faChevronRight} />
        </a>
    </p>
   </>
  );
}


export default MyBrighttalkMessageHeadings;
