import React from 'react';
import classnames from 'classnames';
import {
  Row,
  Col,
  CardDeck,
  Card,
  CardBody,
  CardText,
} from 'reactstrap';
import styles from './skeleton.module.scss';

const SkeletonChannelsGridItems = ({gridCounter}) => {
  const counter = gridCounter;
  return (
    <>
      <Row xs="1" md="2" className={'g-4'} key={'skeleton-channel-row'}>
        {(() => {
          const SkeletonCard = [];
          for (let i = 1; i <= counter; i++) {
            SkeletonCard.push(
              <Col className={styles['column']} key={`TalksCol-` + i}>
                <CardDeck key={`CardDeck-` + i} className={classnames('h-100')}>
                  <Card
                    key={`Card-` + i}
                    className={classnames(
                      styles['list-item'],
                      styles['ChannelsCard'],
					  styles['skeleton-loading'],
                      'h-100'
                    )}
                  >
                    <Row
                      xs={1}
                      sm={2}
                      style={{ height: '100%' }}
                      className={styles['cust-row-height']}
                    >
                      <Col className={styles['col-1-item-body']}>
                        <CardBody
                          key={`cardbody-` + i}
                          className={styles['cardbody']}
                        >
						  <div className={styles['body-comp-logo']}>
							</div>
                          <div
                            key={`main-body` + i}
                            className={styles['main-body']}
                          >
							<div className={styles['item-title']}></div>
                            <CardText
                              tag="p"
                              className={classnames(styles['card-description'])}
                              key={`card-strapline` + i}
                            ></CardText>

                          </div>
                        </CardBody>
                      </Col>

                      <Col className={styles['col-2-img']}>
						<div className={styles['skeleton-summits-wrap']}>
						<div
                          key={`img-wrap` + i}
                          className={styles['img-wrap']}
                        ></div>
						</div>
                      </Col>
                    </Row>
                  </Card>
                </CardDeck>
              </Col>
            );
          }
          return SkeletonCard;
        })()}
      </Row>
    </>
  );
};

export default SkeletonChannelsGridItems;
