import useSWR from 'swr';

// Components
import SectionHeadings from '../../components/SectionHeadings/SectionHeadings';
import SkeletonChannelsGridItems from '../../components/listGridItems/ChannelsGridItems/Skeleton';
import SkeletonChannelsSearchGridItems from '../../components/listGridItems/ChannelsGridItems/Skeleton-Search';
import ChannelsMyBtGrid from '../../components/ChannelsMyBtGrid/ChannelsMyBtGrid';
import ChannelsGrid from '../../components/ChannelsGrid/ChannelsGrid';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';
import NoGridsMessages from '../../components/MyBrighttalkMessageHeadings/MyBrighttalkMessageHeadings';
import Link from 'next/link';
import SkeletonSectionHeadings from '../../components/SectionHeadings/SkeletonSectionHeadings';
// Libraries
import swrUrlParser from '../swrUrlParser/swrUrlParser';
import useSWRFetcher from '../useSWRFetcher';
import {gtmParamsInterface} from '../gtmParamsInterface/gtmParamsInterface'

export const FetchChannels = (baseUrl, params, componentInfo, state = true, gtmParams?: gtmParamsInterface) => {
  const fullUrl = swrUrlParser(componentInfo?.api ? componentInfo?.api : '/api/channels', params);

  const { data, error } = useSWR(state ? fullUrl : null, useSWRFetcher());

  if (error) return <></>;

  let channelsData, showLoadMore;

  if (!data || data['channels']?.length === 0) {
    channelsData = {
      channels: [],
      found: 0,
    };
  } else {
    channelsData = {
      channels: data['channels'],
      found: data['found'],
      links: data['links'],
    };
  }

  if (channelsData['links']) {
    showLoadMore = true;
  } else {
    showLoadMore = false;
  }

  return (
    <>
      {(() => {
       if (!data) {

          if (componentInfo?.pageType === 'search-page') {
            return <SkeletonChannelsSearchGridItems />;

          } else {
            return (
            <>
             <SkeletonSectionHeadings
              skeletonHeadingDescription={componentInfo?.skeletonHeadingDescription}
             />

             <SkeletonChannelsGridItems
              gridCounter={componentInfo?.gridCounter}
             />
            </>
            );
          }

       } else if (channelsData.found > 0){

          if (
            componentInfo?.pageType === 'subscribedChannels' ||
            componentInfo?.pageType === 'manageChannels'
          ) {
            return (
              <ChannelsMyBtGrid
                type={'channels'}
                channelsGrid={channelsData['channels']}
                found={channelsData['found']}
                channelFilterParams={params}
                rowColSm={componentInfo?.rowColSm}
                rowColMd={componentInfo?.rowColMd}
                rowColLg={componentInfo?.rowColLg}
                rowColXl={componentInfo?.rowColXl}
                companyLogo={true}
                gridItemName={componentInfo?.gridItemName}
                footLinkTitle={componentInfo?.footLinkTitle}
                footLinkUnsubscribe={componentInfo?.footLinkUnsubscribe}
                footLinkManageChannel={componentInfo?.footLinkManageChannel}
                footLinkViewChannel={componentInfo?.footLinkViewChannel}
                utmParams={componentInfo?.utmParams}
                dataBddTitle={componentInfo?.dataBddTitle}
                dataBddDesc={componentInfo?.dataBddDesc}
                dataBddSubs={componentInfo?.dataBddSubs}
                dataBddTalksQnt={componentInfo?.dataBddTalksQnt}
                baseUrl={baseUrl}
                priorityStatus={componentInfo?.priorityStatus}
                skeletonGridCounter={componentInfo?.gridCounter}
                skeletonHeadingDescription={
                  componentInfo?.skeletonHeadingDescription
                }
                sectionHeading={componentInfo?.sectionHeading}
                sectionHeadingTitle={componentInfo?.sectionHeadingTitle}
                sectionHeadingDescription={
                  componentInfo?.sectionHeadingDescription
                }
                sectionHeadingLink={componentInfo?.sectionHeadingLink}
                sectionHeadingLinkText={componentInfo?.sectionHeadingLinkText}
                sectionHeadingBddTitle={componentInfo?.sectionHeadingBddTitle}
                sectionHeadingBddDesc={componentInfo?.sectionHeadingBddDesc}
                sectionHeadingBddLink={componentInfo?.sectionHeadingBddLink}
                listLayout={componentInfo?.listLayout}
                cardLayout={componentInfo?.cardLayout}
                pageType={componentInfo?.pageType}
                clickableCard={componentInfo?.clickableCard}
                imgHoverOverlay={componentInfo?.imgHoverOverlay}
                apiUrl={componentInfo.api}
                showLoadMore={showLoadMore}
                gtmParams={gtmParams}
              />
            );
          } else {
            return (
              <ChannelsGrid
                type={'channels'}
                channelsGrid={channelsData['channels']}
                found={channelsData['found']}
                channelFilterParams={params}
                rowColSm={componentInfo?.rowColSm}
                rowColMd={componentInfo?.rowColMd}
                rowColLg={componentInfo?.rowColLg}
                rowColXl={componentInfo?.rowColXl}
                companyLogo={true}
                gridItemName={componentInfo?.gridItemName}
                footLinkManageChannel={componentInfo?.footLinkManageChannel}
                footLinkTitle={componentInfo?.footLinkTitle}
                footLinkViewChannel={componentInfo?.footLinkViewChannel}
                utmParams={componentInfo?.utmParams}
                dataBddTitle={componentInfo?.dataBddTitle}
                dataBddDesc={componentInfo?.dataBddDesc}
                dataBddSubs={componentInfo?.dataBddSubs}
                dataBddTalksQnt={componentInfo?.dataBddTalksQnt}
                baseUrl={baseUrl}
                priorityStatus={componentInfo?.priorityStatus}
                skeletonGridCounter={componentInfo?.gridCounter}
                sectionHeading={componentInfo?.sectionHeading}
                sectionHeadingTitle={componentInfo?.sectionHeadingTitle}
                sectionHeadingDescription={
                  componentInfo?.sectionHeadingDescription
                }
                sectionHeadingLink={componentInfo?.sectionHeadingLink}
                sectionHeadingLinkText={componentInfo?.sectionHeadingLinkText}
                sectionHeadingBddTitle={componentInfo?.sectionHeadingBddTitle}
                sectionHeadingBddDesc={componentInfo?.sectionHeadingBddDesc}
                sectionHeadingBddLink={componentInfo?.sectionHeadingBddLink}
                listLayout={componentInfo?.listLayout}
                cardLayout={componentInfo?.cardLayout}
                pageType={componentInfo?.pageType}
                clickableCard={componentInfo?.clickableCard}
                imgHoverOverlay={componentInfo?.imgHoverOverlay}
              />
            );
          }


       } else {

          if (componentInfo?.pageType === 'search-page') {
            return (
              <>
                <NoResultsFound searchKey={params?.q} />
              </>
            );
          }

          if (componentInfo?.pageType === 'subscribedChannels') {
           return (
            <>

            <NoGridsMessages
             title={componentInfo?.sectionHeadingTitle}
             headingBlockName={componentInfo?.headingBlockName}
             dataBddTitle={componentInfo?.dataBddTitle}
             classes='no-subscribed-channels'
             message=' You haven’t attended any talks yet.'
             messageLinkText=' Explore recent talks '
             messageLinkUrl='/all-talks'
            />

           </>
           );
          }
          return <></>
        }

      })()}
    </>
  );
};

export default FetchChannels;
