import React, { FC, useState, useEffect } from 'react';

// Components
import SectionHeading from '../SectionHeadings/SectionHeadings';
import SkeletonSectionHeadings from '../../components/SectionHeadings/SkeletonSectionHeadings';
import SkeletonChannelsGridItems from '../listGridItems/ChannelsGridItems/Skeleton';
import ChannelsGridItems from '../listGridItems/ChannelsGridItems/ChannelsGridItems';

import LoadMore from '../LoadMore/LoadMore';
import getFetch from '../../libs/getFetch';
import pushTagToDataLayerGA4 from '@bt-react/components/pushTagToDataLayerGA4';
import { gtmParamsInterface } from '../../libs/gtmParamsInterface/gtmParamsInterface';

interface ChannelsProps {
  channelsGrid: ChannelArray[];
  found: number;
  utmParams?: string;
  channelFilterParams?: ChannelFilterParamsArray;
  type?: 'summits' | 'series' | 'channels'; //this isnt strictly required passing in as hardcoded string in callback
  listLayout?: 'horizontal-list' | 'vertical-list'; // we don't want to pass this for talks grid homepage
  cardLayout?: 'horizontal-card' | 'vertical-card';
  clickableCard?: boolean;
  footLinkTitle?: string;
  footLinkUnsubscribe?: boolean;
  footLinkManageChannel?: string;
  footLinkViewChannel?: string;
  gridItemName?: string;
  companyLogo?: boolean;
  listStyle?: string;
  pageType?: string; // needed to pass into the image comp to try and format different resolutions.
  rowColSm?: number;
  rowColMd?: number;
  rowColLg?: number;
  rowColXl?: number;
  imgHoverOverlay?: boolean;
  dataBddTitle?: string;
  dataBddDesc?: string;
  dataBddSubs?: string;
  dataBddTalksQnt?: string;
  baseUrl: string;
  priorityStatus?: boolean;
  sectionHeading: true | false;
  headingBlockName?: string;
  sectionHeadingTitle?: string;
  sectionHeadingDescription?: string;
  skeletonHeadingDescription?: boolean;
  skeletonGridCounter?: number;
  sectionHeadingLink?: string;
  sectionHeadingLinkText?: string;
  sectionHeadingBddTitle?: string;
  sectionHeadingBddDesc?: string;
  sectionHeadingBddLink?: string;
  isFirstBlock?: boolean;
  apiUrl: string;
  showLoadMore: boolean;
  gtmParams?: gtmParamsInterface;
}

export interface ChannelFilterParamsArray {
  size?: number;
  bq?: string;
  rank?: string;
  rankClosest?: string;
}

export interface ChannelArray {
  id: number;
  title: string;
  description: string;
  organisation: string;
  keywords: string;
  keywordsList: string[];
  strapline: string;
  url: string;
  searchVisibility: string;
  rating: number;
  statistics: Statistics;
  features: Feature[];
  links: Link[];
  featuredCommunications: FeaturedCommunication[];
}

export interface Statistics {
  upcomingCommunications: number;
  liveCommunications: number;
  recordedCommunications: number;
  subscribers: number;
  viewedSeconds: number;
}

export interface Feature {
  name: string;
  enabled: boolean;
  value?: string;
}

export interface Link {
  href: string;
  rel: string;
  type: string;
  title?: string;
}

export interface FeaturedCommunication {
  id: number;
  title: string;
  description: string;
  start: string;
  duration: number;
  provider: string;
  thumbnailLink: ThumbnailLink;
  links: FeaturedCommunicationLink[];
}

export interface ThumbnailLink {
  href: string;
  rel: string;
  type: string;
  title: string;
}

export interface FeaturedCommunicationLink {
  href: string;
  rel: string;
  type: string;
  title: string;
}

const ChannelsMyBtGrid: FC<ChannelsProps> = ({
  channelsGrid,
  utmParams = '',
  channelFilterParams,
  listLayout,
  cardLayout,
  clickableCard,
  footLinkTitle,
  footLinkUnsubscribe,
  footLinkManageChannel,
  footLinkViewChannel,
  gridItemName,
  pageType,
  rowColSm,
  rowColMd,
  rowColLg,
  rowColXl,
  listStyle,
  companyLogo,
  imgHoverOverlay,
  dataBddTitle,
  dataBddDesc,
  dataBddSubs,
  dataBddTalksQnt,
  baseUrl,
  priorityStatus,
  sectionHeading,
  headingBlockName,
  sectionHeadingTitle,
  sectionHeadingDescription,
  skeletonHeadingDescription,
  skeletonGridCounter,
  sectionHeadingLink,
  sectionHeadingLinkText,
  sectionHeadingBddTitle,
  sectionHeadingBddDesc,
  sectionHeadingBddLink,
  isFirstBlock,
  apiUrl,
  showLoadMore,
  gtmParams,
}) => {

  const [channelsData, setChannelsData] = useState(channelsGrid);
  const [pageCount, setPageCount] = useState<number>(channelFilterParams['page']+ 1);
  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [displayLoadMore, setDisplayLoadMore] = useState<boolean>(showLoadMore)

  useEffect(() => {
    const getSummits = async () => {
      setIsLoading(channelsGrid && false);
    };
    getSummits();
  });

  if ( gtmParams?.displayGTM ) {
    // Push events to the GTM GA3 for display.
    pushTagToDataLayerGA4('tracking_event', {
      'brighttalk-entity': gtmParams?.displayGTM?.display_category,
      'brighttalk-action': gtmParams?.displayGTM?.display_action,
      'brighttalk-label': gtmParams?.displayGTM?.display_subtype
    })

    // Push events to the GTM GA4 for display.
    pushTagToDataLayerGA4('display_tt', gtmParams?.displayGTM)
  }

  const loadMoreBtn = async () => {
    if ( gtmParams?.clickGTM ) {
      // Push events to the GTM GA3 for load more click.
      pushTagToDataLayerGA4('tracking_event', {
        'brighttalk-entity': gtmParams?.clickGTM?.click_category,
        'brighttalk-action': gtmParams?.clickGTM?.click_action,
        'brighttalk-label': gtmParams?.clickGTM?.click_subtype
      })

      // Push events to the GTM GA4 for load more click.
      pushTagToDataLayerGA4('click_tt', gtmParams?.clickGTM)
    }

    setShowLoadMoreBtn(true);

    // Fetch new set of talks. We call latestTalks endpoint via backend to avoid CORS issue
    const resJson = await getFetch(baseUrl + apiUrl, {
      page: pageCount,
	  pageSize: channelFilterParams['pageSize'],
	  sortBy: channelFilterParams['sortBy'],
	  dateTimeFormat: channelFilterParams['dateTimeFormat'],
	  sortOrder: channelFilterParams['sortOrder'],
    });

    //Push new set of data into existing array. Using JS spread operator.
    setChannelsData((oldTalksData) => [
      ...oldTalksData,
      ...resJson['channels'],
    ]);

    setPageCount((prevValue) => prevValue + 1);

	// Check to display load more button.
	resJson?.['links'].forEach(element => {
		if ( element?.['rel'] === 'next' ) {
			setDisplayLoadMore(true)
		} else {
			setDisplayLoadMore(false)
		}
	});

    // Turn off loading spinner
    setShowLoadMoreBtn(false);
  };

  return <div data-bdd="channels-grid">

  {(() => {

    if ( sectionHeading == true ) {

      if ( isLoading ) {

       return (

        <SkeletonSectionHeadings

         skeletonHeadingDescription={skeletonHeadingDescription}
         {... (isFirstBlock == true &&  {classes: "no-margin-top" } ) }
        />
       );

      } else if  ( ChannelsMyBtGrid?.length > 0 ) {

       return (

         <SectionHeading
          title={sectionHeadingTitle}
          description={sectionHeadingDescription}
          link={sectionHeadingLink}
          linkTitle={sectionHeadingLinkText}
          headingBlockName={headingBlockName}
          dataBddTitle={sectionHeadingBddTitle}
          dataBddDesc={sectionHeadingBddDesc}
          dataBddLink={sectionHeadingBddLink}
          {... (isFirstBlock == true &&  {classes: "no-margin-top" } ) }
          />
        );

      } else {
       <></>
      }

     }

   })()}

   {(() => {

     if ( isLoading )  {

       return (
        <SkeletonChannelsGridItems gridCounter={skeletonGridCounter} />
       );

     } else if ( ChannelsMyBtGrid?.length > 0 ) {

      return (
       <>

        <ChannelsGridItems
         gridData={channelsData}
         gridItemName={gridItemName}
         type={'channels'}
         rowColSm={rowColSm}
         rowColMd={rowColMd}
         rowColLg={rowColLg}
         rowColXl={rowColXl}
         listStyle={listStyle}
         listLayout={listLayout}
         cardLayout={cardLayout}
         clickableCard={clickableCard}
         footLinkTitle={footLinkTitle}
		 footLinkManageChannel={footLinkManageChannel}
		 footLinkViewChannel={footLinkViewChannel}
         footLinkUnsubscribe={footLinkUnsubscribe}
         companyLogo={companyLogo}
         pageType={pageType}
         utmParams={utmParams}
         imgHoverOverlay={imgHoverOverlay}
         dataBddTitle={dataBddTitle}
         dataBddDesc={dataBddDesc}
         dataBddSubs={dataBddSubs}
         dataBddTalksQnt={dataBddTalksQnt}
         baseUrl={baseUrl}
         priorityStatus={priorityStatus}
        />

        <LoadMore
         onClick={loadMoreBtn}
         loader={showLoadMoreBtn}
         showLoadMore={displayLoadMore}
         gridItemName={gridItemName}
        />
       </>
      );

    } else {
      <></>
    }

   })()}

  </div>

};

export default ChannelsMyBtGrid;
