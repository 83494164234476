import getFetch from '../../libs/getFetch';
import pushTagToDataLayerGA4 from '@bt-react/components/pushTagToDataLayerGA4';

const RemoveWatchLaterCard = async (
  e,
  setLoading,
  channelId,
  index
) => {
  e.preventDefault();
  // Turn on loading spinner
  setLoading(true);

  const channelIdParams = {
    channelId: channelId,
  };

  const data = await getFetch(
	'/api/channels/deleteChannelSubscribed',
	channelIdParams
  );

  if (data.status === 'success') {

    // Push events to the GTM GA3 for remove watch-later.
    pushTagToDataLayerGA4('tracking_event', {
      'brighttalk-entity': 'My BrightTALK',
      'brighttalk-action': 'unsubscribe',
      'brighttalk-label': ''
    })

    // Push events to the GTM GA4 for remove watch-later.
    pushTagToDataLayerGA4('click_tt', {
      'click_category': 'My BrightTALK',
      'click_action': 'Channel click',
      'click_subtype': 'Unsubscribe'
    })

    // Find element now by ID and remove it the grid.
    document
      .getElementById(`ChannelsCol-subscribedChannels` + index)
      .remove();

  } else {
    // error message
  }

  // Turn off loading spinner
  setLoading(false);
};

export default RemoveWatchLaterCard;
